import React from "react";

import jumbotron from "../../cms/settings/jumbotron.json"

export default function Jumbotron() {

  return (
    <section>
      <div className="lg:container mx-auto">
        <div className="relative overflow-hidden inline-block w-full h-[48rem]">
         <img src={jumbotron.image} className="absolute object-cover w-full h-full -z-50" alt="jumbotron" />
          <div className="flex items-center justify-center w-full h-full">
            <div className="w-full md:px-10 py-5 md:mx-32 text-center text-white md:rounded bg-black/50">
              <h1 className="mb-10 text-4xl md:text-5xl font-extralight uppercase">
                {jumbotron.header}
              </h1>
              <div className="space-y-1 text-lg md:text-xl font-extralight">
                <div>{jumbotron.line1}</div>
                <div>{jumbotron.line2}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}