import React from "react";
// import { GatsbyImage, getImage } from "gatsby-plugin-image";

export default function Feature({ img, header, article }) {
  // const image = getImage(img);

  return (
    <div className="w-72 sm:w-48 lg:w-64 px-4 py-6 shadow-sm flex-none">
      <div>
        <img
          src={img}
          alt={header}
          className="object-cover w-full h-full rounded-full"
        />
      </div>

      {/* <GatsbyImage image={image} alt={header} className="rounded-full" /> */}
      {/* <img src={img} alt={header} /> */}
      <h1 className="mt-8 text-lg font-semibold text-center uppercase">
        {header}
      </h1>
      <article className="mt-4 text-sm text-center font-extralight">
        {article}
      </article>
    </div>
  );
}
