import React from 'react'

export default function ExternalLink({href, text}) {
    return (
      <a
        href={href}
        className="hover:underline text-green-500 hover:text-green-600 "
      >
        {text}
      </a>
    );
}