import React, { useEffect, useState, useCallback } from "react";
import { useStaticQuery, graphql } from "gatsby";
import useEmblaCarousel from "embla-carousel-react";

import {
  FaRegCircle,
  FaCircle,
  FaChevronLeft,
  FaChevronRight,
} from "react-icons/fa";

import Section from "./section";

export default function Testimonials() {
  const [emblaRef, emblaApi] = useEmblaCarousel({ loop: true });
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [scrollSnaps, setScrollSnaps] = useState([]);

  const data = useStaticQuery(graphql`
    {
      allMarkdownRemark(
        filter: { fileAbsolutePath: { regex: "/(content/testimonials)/" } }
      ) {
        nodes {
          id
          frontmatter {
            testimonial
            customer
          }
        }
      }
    }
  `);

  const testimonialsArray = [];

  data.allMarkdownRemark.nodes.forEach((node) => {
    const testimonial = {
      customer: node.frontmatter.customer,
      text: node.frontmatter.testimonial,
    };
    testimonialsArray.push(testimonial);
  });

  const scrollPrev = useCallback(() => {
    emblaApi && emblaApi.scrollPrev();
    setSelectedIndex(setSelectedIndex - 1);
  }, [emblaApi]);
  const scrollNext = useCallback(() => {
    emblaApi && emblaApi.scrollNext();
    setSelectedIndex(setSelectedIndex + 1);
  }, [emblaApi]);
  // const scrollTo = useCallback(
  //   (index) => emblaApi && emblaApi.scrollTo(index),
  //   [emblaApi]
  // );

  useEffect(() => {
    if (emblaApi) {
      setScrollSnaps(emblaApi.scrollSnapList());
      setSelectedIndex(emblaApi.selectedScrollSnap());
    }
  }, [emblaApi, setScrollSnaps, selectedIndex]);

  return (
    <Section
      id="testimonials"
      header="řekli o nás"
      subheader="Co o práci s našimi produkty říkají ostatní"
      theme="dark"
    >
      <div className="space-y-8">
        <div className="flex items-center justify-center space-x-4">
          <button onClick={scrollPrev}>
            <FaChevronLeft />
          </button>
          <div className="embla" ref={emblaRef}>
            <div className="embla__container">
              {testimonialsArray.map((testinomial, index) => (
                <div key={index} className="space-y-2 text-center embla__slide">
                  <div className="text-3xl font-extralight">
                    {testinomial.text}
                  </div>
                  <div className="font-light">- {testinomial.customer}</div>
                </div>
              ))}
            </div>
          </div>

          <button onClick={scrollNext}>
            <FaChevronRight />
          </button>
        </div>
        <div className="flex justify-center space-x-2 embla__dots">
          {scrollSnaps.map((_, index) => (
            <div key={index}>
              {index === selectedIndex ? <FaCircle /> : <FaRegCircle />}
            </div>
          ))}
        </div>
      </div>
    </Section>
  );
}
