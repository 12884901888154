import React from "react";
import { Helmet } from "react-helmet";

import Features from "../components/features";
import Jumbotron from "../components/jumbotron";
import Testimonials from "../components/testimonials";
import ContactForm from "../components/contactForm";
import Tints from "../components/tints";
import Layout from "../components/layout";

export default function Home() {
  return (
    <>
      <Helmet>
        <title>Vosky Na Dřevo</title>
      </Helmet>
      <Layout>
        <Jumbotron />
        <Features />
        <Testimonials />
        <Tints />
        <ContactForm />
      </Layout>
    </>
  );
}
