import React, { useState, useEffect } from "react";
import { useForm } from "@formspree/react";
import { FaPaperPlane } from "react-icons/fa";

import Section from "./section";
import ExternalLink from "./externalLink";
import useModal from "../hooks/useModal"

import contactInfo from "../../cms/settings/contact_info.json";

export default function ContactForm() {
  const {Modal, modalIsHidden, setModalIsHidden} = useModal()

  const [name, setName] = useState("");
  const [mail, setMail] = useState("");
  const [phone, setPhone] = useState("");
  const [msg, setMsg] = useState("");

  const [state, handleSubmit] = useForm(
    process.env.GATSBY_FORMSPREE_CONTACT_ID,
    {
      data: {
        sendForm: () => {
          clearForm();
          setModalIsHidden(false);
        },
      },
    }
  );

  const inputFieldStyle =
    "w-full px-6 py-2 bg-gray-300 rounded focus:outline-none focus:shadow";
  const submitButtonStyle =
    "bg-green-700 hover:bg-green-600 text-gray-50 px-8 py-2 font-medium text-lg rounded-lg mt-4";

  function clearForm() {
    setName("");
    setMail("");
    setPhone("");
    setMsg("");
  }

  useEffect(() => {
    const timer = setTimeout(() => {
      setModalIsHidden(true);
    }, 3000);
    return () => clearTimeout(timer);
  }, [modalIsHidden, setModalIsHidden]);

  return (
    <Section
      id="contactForm"
      header="Kontakt"
      subheader="Máte-li jakékoliv dotazy, či návrhy, neváhejte nám napsat."
      text="text-gray-800"
      theme="dark"
    >
      <Modal>
        <div className="flex w-full text-center justify-center mb-4">
          <FaPaperPlane className="text-3xl " />
        </div>
        <div className="uppercase mb-4 font-semibold text-lg">děkujeme,</div>
        <div className="">
          Váš dotaz byl odeslán. V blízké době se Vám ozveme.
        </div>
      </Modal>

      <div className="w-full text-black">
        <div className="flex items-center justify-center">
          <form onSubmit={handleSubmit} className="mb-10 space-y-2 w-[32rem]">
            <div>
              <input
                className={inputFieldStyle}
                type="text"
                id="name"
                name="name"
                value={name}
                onChange={(e) => setName(e.target.value)}
                placeholder="Jméno a příjmení *"
                required
              />
            </div>
            <div>
              <input
                className={inputFieldStyle}
                type="mail"
                id="mail"
                name="mail"
                value={mail}
                onChange={(e) => setMail(e.target.value)}
                placeholder="Email *"
                required
              />
            </div>
            <div>
              <input
                className={inputFieldStyle}
                type="tel"
                id="phone"
                name="phone"
                value={phone}
                onChange={(e) => setPhone(e.target.value)}
                placeholder="Telefon"
              />
            </div>
            <div>
              <textarea
                className={inputFieldStyle + " h-32"}
                type="text"
                id="msg"
                name="msg"
                value={msg}
                onChange={(e) => setMsg(e.target.value)}
                placeholder="Vaše zpráva *"
                required
              />
              <div className="text-xs text-gray-400">
                Pole označená hvězdičkou (*) jsou povinná
              </div>
            </div>

            <div className="text-center">
              <input
                className={submitButtonStyle}
                type="submit"
                name="send"
                value="Odeslat"
                disabled={state.submitting}
              />
            </div>
          </form>
        </div>
      </div>
      <div className="text-center">
        V případě potřeby nás neváhejte kontaktovat emailem na{" "}
        <ExternalLink
          href={`mailto:${contactInfo.email}`}
          text={`${contactInfo.email}`}
        />{" "}
        nebo na telefonním čísle{" "}
        <ExternalLink
          href={`tel:${contactInfo.phone}`}
          text={`${contactInfo.phone}`}
        />
        .
      </div>
    </Section>
  );
}
