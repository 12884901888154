import React from "react";
import { useStaticQuery, graphql } from "gatsby";

import Feature from "./feature";
import Section from "./section";

export default function Features() {
  const data = useStaticQuery(graphql`
    {
      allMarkdownRemark(
        filter: { fileAbsolutePath: { regex: "/(content/features)/" } }
        sort: { fields: frontmatter___order, order: ASC }
      ) {
        nodes {
          id
          frontmatter {
            title
            description
            order
            image
          }
        }
      }
    }
  `);

  const featuresArray = [];

  data.allMarkdownRemark.nodes.forEach((node) => {
    const feature = {
      header: node.frontmatter.title,
      article: node.frontmatter.description,
      img: node.frontmatter.image,
    };
    featuresArray.push(feature);
  });

  return (
    <Section id="features" theme="light">
      <div className="grid grid-cols-1 justify-items-center gap-y-10 sm:gap-0 m-10 sm:flex sm:justify-center sm:content-start  sm:space-y-0 sm:space-x-10 lg:space-x-24">
        {featuresArray.map((feature, index) => (
          <Feature
            key={index}
            img={feature.img}
            header={feature.header}
            article={feature.article}
          />
        ))}
      </div>
    </Section>
  );
}
