import React from "react";

import useModal from "../hooks/useModal";

export default function TintDetail({ tintName, tintImg }) {
  const {Modal, showModal} = useModal()

  return (
    <>
      <Modal>
        <img
          src={tintImg}
          alt={tintName}
          className="object-cover w-full h-full"
        />
        <span className="text-gray-800">{tintName}</span>
      </Modal>

      <button onClick={showModal} className="hover:shadow-sm relative h-48">
        <img
          src={tintImg}
          alt={tintName}
          className="object-cover w-full h-full"
        />
        <div className="absolute bottom-0 w-full bg-gradient-to-t from-black/50 via-black/30 to-black/10 text-center py-1">
          <span className="text-gray-100">{tintName}</span>
        </div>
      </button>
    </>
  );
}
