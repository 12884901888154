import React from "react";
import { useStaticQuery, graphql } from "gatsby";

import TintDetail from "./tintDetail";
import Section from "./section";

export default function Tints() {
  const data = useStaticQuery(graphql`
    {
      allMarkdownRemark(
        filter: { fileAbsolutePath: { regex: "/(content/tints)/" } }
        sort: { fields: frontmatter___name, order: ASC }
      ) {
        nodes {
          frontmatter {
            name
            order
            image
          }
        }
      }
    }
  `);
  const tintsArray = [];

  data.allMarkdownRemark.nodes.forEach((node) => {
    const tint = {
      name: node.frontmatter.name,
      img: node.frontmatter.image,
    };
    tintsArray.push(tint);
  });

  return (
    <Section
      id="tints"
      header="dostupné odstíny"
      subheader="Pro dosažení požadovaného vzhledu je k dispozic 9 barevných variant v
          baleních 0,4l nebo 5l"
      theme="light"
    >
      <div className="grid lg:grid-cols-3 md:grid-cols-auto gap-6">
        {tintsArray.map((tint, index) => (
          <TintDetail key={index} tintName={tint.name} tintImg={tint.img} />
        ))}
      </div>
    </Section>
  );
}
